<template>
	<div class="favor-list">
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh" success-text="刷新成功" animation-duration="800" >
			<van-list
				v-model="loading"
				:finished="finished"
				finished-text="已全部加载"
				@load="onLoad"
			>
				<div class="favor-item" v-for="(item, idx) in favorList" :key="idx">
					<div class="item-left">
                        <van-image
                            round
                            width="0.8rem"
                            height="0.8rem"
                            :src="!!item.avatar ? 'http://' + item.avatar : 'https://doc.yctop.com/weapp/zjhy/static/2020-12-15/default-logo.png'"
                            class="item-img"
							@click="gotodetail(item.id)"
                        />
                        <div class="item-detail">
                            <p class="item-name">{{item.name}}</p>
                            <p class="item-title">{{item.company_title}}</p>
                        </div>
                    </div>
                    <div class="item-right">
						<van-button :class="item.favored==true ? 'favor-btn favor-tmp2':'favor-btn favor-tmp1'" @click="favorChange(idx, item.favored)">
                           	<i :class="item.favored==true ?'hidden' :'iconjia iconfont'"></i>
							{{item.favored==true ? '已关注':'关注'}}
                        </van-button>
                    </div>
				</div>
			</van-list>
		</van-pull-refresh>
	

	</div>
	
</template>
<script>
/* eslint-disable */
	import { mapState, mapGetters } from 'vuex';
	import { Dialog } from 'vant';
	import  REQ_HANDLE from '@/utils/request.js'
	export default {
		data: ()=> ({
			favorList:[],
			loading: false,
			finished: false,
			refreshing: false,
			pageIdx: 1,
			cnt:0,
		}),
		computed: {
			...mapState({
				usr: state => state.usr,
				token: state => state.token,
				wx_usr: state => state.wx_usr,
			}),
			...mapGetters({
				login: 'checkLogin',
			})
		},
		created: function() {
			
		},
		mounted: async function() {
			await this.loadFavorList('refresh')
		},
		methods: {
			favorChange: async function(idx, favored){
				favored==true ? this.cancelFavored(idx) : await this.isFavored(idx)
			},
			isFavored: async function(idx){
				let url = '/api-mall/favor',
					_this=this,
					params={
						agent_id: _this.favorList[idx].id
					},
					res = await REQ_HANDLE.agent.$post(url, params)
				if(!!res){
					const list=[..._this.favorList]
					list[idx].favored=!list[idx].favored
					_this.favorList=list
				}
			},
			cancelFavored: function(idx){
				let _this=this
				Dialog.confirm({
					message: '确认取消关注？',
					confirmButtonText:'取消关注',
					cancelButtonText:'再想想',
				})
				.then( async () => {
					let url = '/api-mall/favor/' + _this.favorList[idx].id,
					res = await REQ_HANDLE.agent.$delete(url)
					if(!!res){
						const list=[..._this.favorList]
						list[idx].favored=!list[idx].favored
						_this.favorList=list
					}
				})
				.catch(() => {
					// on cancel
				});
			},
			loadFavorList: async function(type) {
				let url = '/api-mall/favor/list/shops',
					params={
						pageIdx:this.pageIdx,
						pageSize: 10,
					},
					res = await REQ_HANDLE.agent.$get(url, params),
					that=this
					that.refreshing = false;
					that.loading = false;
				if(!!res) {
					if(type=='refresh'){
						that.favorList = res.data.result
					}else{
						that.favorList =that.favorList.concat(res.data.result);
					}
					if(that.favorList.length && that.favorList.length>0){
						for(var i=0;i<that.favorList.length;i++){
							that.favorList[i].favored=true
						}
					}

					that.cnt=res.data.cnt
					that.finished = res.data.cnt == that.favorList.length
				}
			},
			onRefresh: async function(){
				this.refreshing = true;
				this.pageIdx=1
				await this.loadFavorList('refresh')
			},
			onLoad: async function(){
				if(this.favorList.length==0){
					return
				}
				if(this.favorList.length && this.favorList.length==this.cnt){
					this.finished=true
					this.loading = false;
					return
				}
				this.loading = true;
				this.pageIdx++
				await this.loadFavorList('more')
			},
			gotodetail: function(id){
				!!id && this.$router.push('/shop/'+id)
			}
		}
			
	};
</script>
<style scoped >
@import "~@/assets/css/favorList.css";
</style>